import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The Des Moines Register has been publishing an `}<a parentName="p" {...{
        "href": "http://www.desmoinesregister.com/topic/15d1eaba-146f-4158-a2ed-f34dba805d1e/debit-card-investigation/"
      }}>{`ongoing investigation into prepaid cards`}</a>{`, consisting of a series of articles, letters and opinion pieces. There's a growing controversy around the benefits of prepaid debit cards used as payroll cards onto which employees can receive their pay through direct deposit. PayPerks believes that the cards are beneficial for consumers when paired with education about how to use the cards correctly.`}</p>
    <p>{`The Register yesterday published `}<a parentName="p" {...{
        "href": "http://www.desmoinesregister.com/story/opinion/columnists/2014/04/24/prepaid-cards-beneficial/8082741/"
      }}>{`this editorial piece`}</a>{` written by PayPerks' CEO Arlyn Davich explains our point of view and her history with prepaid debit cards. We have reposted the piece here for you to read:`}</p>
    <blockquote>
      <p parentName="blockquote"><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "716px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "11.249999999999998%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAYAAABYBvyLAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAXklEQVQI1y3MvQqCYBgF4I/nWoSGRjEQIQKHllyFMPAHp8QtaGxp7Z7jg3c6/yfhjQ01LvihRIMvdrQ4o8IBL/Q4xeaGK44JdzzwRIE1dC4sMR7Dy1mHIfgncMaUz/9VEQ5C/akjzwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
              "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/fb10f13a74e1f19332c78d335322c176/7f61c/DesMoinesRegister1.webp 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/fb10f13a74e1f19332c78d335322c176/d8378/DesMoinesRegister1.webp 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/fb10f13a74e1f19332c78d335322c176/e17e5/DesMoinesRegister1.png 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/fb10f13a74e1f19332c78d335322c176/6bbf7/DesMoinesRegister1.png 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "https://cdn.payperks.com/payperks-corporate/1.16.1/static/fb10f13a74e1f19332c78d335322c176/6bbf7/DesMoinesRegister1.png",
              "alt": "DesMoinesRegister",
              "title": "DesMoinesRegister",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
    `}</span></p>
      <p parentName="blockquote"><strong parentName="p">{`Another View: Prepaid cards are beneficial for Iowans who are ‘unbanked’
`}</strong>{`By Arlyn Davich
11 p.m. CDT April 23, 2014
`}<a parentName="p" {...{
          "href": "http://www.desmoinesregister.com/story/opinion/columnists/2014/04/24/prepaid-cards-beneficial/8082741/"
        }}>{`http://www.desmoinesregister.com/story/opinion/columnists/2014/04/24/prepaid-cards-beneficial/8082741/`}</a></p>
      <p parentName="blockquote">{`The Des Moines Register’s insightful investigation into prepaid cards highlighted the fact that, since they were introduced a few short years ago, they have not always pleased everyone.`}</p>
      <p parentName="blockquote">{`It was not long ago that I shared the skepticism of many of the recent editorials. Since then, as my knowledge of the prepaid cards has evolved alongside the card programs themselves, my mind has been changed. I have come to see prepaid cards as an essential part of the payments ecosystem — a highly effective means to provide low- and middle-income consumers with access to mainstream financial services from which they would otherwise be shut out.`}</p>
      <p parentName="blockquote">{`According to the FDIC, over 20 percent of Iowa households are not fully served by the traditional banking system, and many of these do not have a bank account at all.`}</p>
      <p parentName="blockquote">{`For these people, receiving wages or benefits by paper check means a visit to a high-fee check casher, which can cost up to 3 percent of the value of a check. Once a check is cashed, additional fees of around \\$1.50 per utility bill can be incurred for paying in cash. On top of this, we must consider time spent waiting in line to pay bills and that cash is vulnerable to loss or theft.`}</p>
      <p parentName="blockquote">{`I was introduced to this set of problems at business school. As I searched for a solution, my initial instinct was that employers should work to “bank” their unbanked employees. But the more unbanked people I spoke to, the more I realized that the reasons for not having a bank account were as diverse as the unbanked population itself — from not being able to qualify for an account, to not being able to afford the minimum balance requirements for free checking.`}</p>
      <p parentName="blockquote">{`It was clear that a bank account does not suit everyone’s needs.`}</p>
      <p parentName="blockquote">{`At that point, I learned about payroll cards: prepaid debit cards onto which an employer can direct deposit an employee’s pay. Like other forms of prepaid card, employees can access their funds by swiping their card at check-out (for no fee) or through an ATM (often times, for no fee). Their money is usually protected from fraud or theft by the card networks’ zero liability policies.`}</p>
      <p parentName="blockquote">{`As with all financial products, consumers must understand where charges apply and use their cards in ways that ensure they are minimizing fees and maximizing their benefits, but, when properly utilized, these cards are an undoubtedly safer and cheaper alternative to the status quo.`}</p>
      <p parentName="blockquote">{`In fact, it is estimated that the average unbanked payee will save a week’s worth of salary over the course of a year by switching wages from paper checks to direct deposit.`}</p>
      <p parentName="blockquote">{`It was this insight that led to the formation of PayPerks, a financial capability and rewards program that is layered on prepaid card programs to incentivize cardholders to learn about and use their card in ways that help them access their numerous benefits.`}</p>
      <p parentName="blockquote">{`PayPerks is a good example of the wider evolution that has taken place since prepaid cards first became available. That this evolution has been firmly in the interests of cardholders is not in doubt.`}</p>
      <p parentName="blockquote">{`In fact, the highly respected Center for Financial Services Innovation recently published a scorecard which awarded the prepaid industry with an A– grade for meeting the standards for a high-quality prepaid account. Fee transparency and cardholder education were among the key considerations for this.`}</p>
      <p parentName="blockquote">{`The evolution of prepaid cards has been shepherded by regular guidance and best practice standards published by the card networks. Prepaid payroll card standards, for example, have emphasized, alongside fee transparency and user education, the importance of giving employees a choice of payment types and reinforcing the need for cardholders to be able to get their money as cash, in full, for free, at least once per pay period.`}</p>
      <p parentName="blockquote">{`All of this adds up to a prepaid card system that is becoming safer and more valuable for those who have come to rely on it. I am not the only one who has woken up to their potential to ease the burden on unbanked individuals and families. Notably, the U.S. Treasury is accommodating the needs of unbanked benefit recipients through its Direct Express prepaid card and is actually negotiating preferred rates on behalf of its prepaid cardholders.`}</p>
      <p parentName="blockquote">{`We should celebrate the fact that governments, employers and financial institutions are supporting a more inclusive payments ecosystem. Iowans should understand that easy-to-understand, and simple-to-use, prepaid cards can bring huge benefits to unbanked families in the state. That, surely, is something we can all get behind?`}</p>
      <p parentName="blockquote">{`THE AUTHOR:`}</p>
      <p parentName="blockquote">{`ARLYN DAVICH is the founder and CEO of PayPerks, a New York City company that serves financially vulnerable Americans.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      